/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { Stack, Button } from '@chakra-ui/core';
import ContentContainer from '@bit/matternet.shared.components.content-container';
import { Card, CardContent } from '@bit/matternet.shared.components.card';
import { signIn } from 'next-auth/client';

const LogIn = () => {
  return (
    <ContentContainer>
      <Card heading="Credentials Required" data-testid="login">
        <CardContent>
          <Stack shouldWrapChildren>
            {/* Here we specify the exact auth provider we want the client to use */}
            <Button data-testid="login-button" onClick={() => signIn('auth0')}>
              Log In
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </ContentContainer>
  );
};

export default LogIn;
